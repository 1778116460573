import React, { ReactElement } from 'react';
import { AspectRatio } from '@geberit/gdds';

import { ImageStyles, Size } from '../../tile.types';

import {
  ImageWithHeadlineContainer,
  ImageContainer,
  ImageWrapper,
} from './image-with-headline.styles';

const aspcetRatios = {
  s: {
    small: '16:9',
    medium: '1:1',
    large: '1:1',
    xlarge: '',
  },
  m: {
    small: '16:9',
    medium: '',
    large: '',
    xlarge: '',
  },
};

interface ImageWithHeadlineProps {
  image: string;
  imageTitle: string;
  imageStyles: ImageStyles;
  size: Size;
  hovered: boolean;
  altText: string;
  breakPoint: string;
  tileHeight: number;
}

export function ImageWithHeadline({
  image,
  imageTitle,
  imageStyles = { size: 'cover', repeat: false },
  size,
  hovered,
  altText,
  breakPoint,
  tileHeight,
}: Partial<Omit<ImageWithHeadlineProps, 'size'>> & { size: Size }): ReactElement {
  const imageContainer = !!image && (
    <ImageContainer className="image-container">
      <ImageWrapper
        title={imageTitle}
        role="img"
        aria-label={altText}
        imageStyles={imageStyles}
        imageSource={image}
      />
    </ImageContainer>
  );

  const ratio = aspcetRatios[size][breakPoint];

  return (
    <ImageWithHeadlineContainer
      className="image-container"
      tileHeight={tileHeight}
      hovered={hovered}
      size={size}
    >
      {ratio ? <AspectRatio ratio={ratio}>{imageContainer}</AspectRatio> : imageContainer}
    </ImageWithHeadlineContainer>
  );
}
