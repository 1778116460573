// components
import { SearchContextProvider } from '../locator/search';
import { VideoConsent } from './video';
import { ShowroomLocatorTileContent } from './showroom-locator-tile-content';

// styles
import { StyledWrapper } from 'components/ContentElements/showroom-locator-teaser/showroom-locator-teaser.styles';

// types
import type { TileBackgroundColorProps } from './types';
import type { ShowroomLocatorTileProps } from './showroom-locator-tile-content';

// utils
import { useUCConsent } from 'features/cookie-consent';
import { useLocatorConfiguration } from '../locator/utils/use-locator-configuration';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { Log } from 'services/log';
import { ShowroomLocatorTileWrapper } from './showroom-locator-tile-wrapper.styles';

export function ShowroomLocatorTile({
  backgroundType,
  ...props
}: Readonly<ShowroomLocatorTileProps & { backgroundType: TileBackgroundColorProps }>) {
  const consent = useUCConsent();
  const isPreview = useIsPreview();
  const locatorConfiguration = useLocatorConfiguration();
  const locatorUrl = locatorConfiguration.url;
  const showConsent = consent.Comfort === false && locatorConfiguration !== undefined;

  if (!locatorUrl) {
    if (isPreview) {
      return (
        <StyledWrapper>
          <p>locator url is missing!</p>
        </StyledWrapper>
      );
    }

    Log.error('locatorUrl is missing!');
    return null;
  }

  return (
    <SearchContextProvider locatorUrl={locatorUrl}>
      <ShowroomLocatorTileWrapper backgroundType={backgroundType} showConsent={showConsent}>
        {showConsent && <VideoConsent />}
        {!showConsent && <ShowroomLocatorTileContent {...props} />}
      </ShowroomLocatorTileWrapper>
    </SearchContextProvider>
  );
}
