import { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import type { ThemeType as Theme } from '@geberit/gdds';

// styles
import {
  BorderLayer,
  StyledDiv,
  StyledLink,
  StyledParagraph,
  StyledParagraphContainer,
  StyledTypo,
  TransparentLayer,
} from '../common/common.styles';
import { HeadlineWithShortTeaserContainer } from './headline-with-short-teaser.styles';

// types
import type { HeadlineWithShortTeaserProps } from '../../tile.types';

// components
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { useKolo } from 'utils/hooks/use-kolo';
import { previewId as generatePreviewId } from 'utils/preview-id';
import { useNord } from 'utils/hooks/use-nord';
import { useTwyford } from 'utils/hooks/use-twyford';

export function Tlea2({
  title,
  subTitle,
  size,
  paragraph,
  link,
  backgroundType,
  customBackground,
  children,
  fontSize = 'p2',
  gap,
  label,
  previewId,
  titlePreviewId,
  subtitlePreviewId,
  textPreviewId,
}: Omit<HeadlineWithShortTeaserProps, 'customBackground'> & {
  customBackground?: string;
}) {
  const theme = useTheme() as Theme;
  const [focused, setFocused] = useState(false);
  const [height, setHeight] = useState(0);
  const tileRef = useRef(null);
  const isNord = useNord();
  const isKolo = useKolo();
  const isTwyford = useTwyford();

  function setWidth() {
    if (tileRef.current === null) return;
    const tileWidth = Number(window.getComputedStyle(tileRef.current).width.replace('px', ''));
    setHeight(tileWidth);
  }

  useEffect(() => {
    setWidth();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setWidth);
    return () => window.removeEventListener('resize', setWidth); // cannot test unmount
  }, []);

  let spanFontWeight = theme.fontWeights.regular;
  if (isKolo) {
    spanFontWeight = theme.fontWeights.bold;
  } else if (isTwyford) {
    spanFontWeight = theme.fontWeights.medium;
  } else if (isNord) {
    spanFontWeight = theme.fontWeights.normal;
  }

  let linkStylingType: 'inverted' | 'primary' | 'invertedOnColor' = 'inverted';
  if (backgroundType === 'light') {
    linkStylingType = 'primary';
  } else if (backgroundType === 'dark') {
    linkStylingType = 'invertedOnColor';
  }

  let textFontWeight = isTwyford ? theme.fontWeights.light : theme.fontWeights.normal;
  if (isKolo) textFontWeight = theme.fontWeights.bold;

  function renderHeadline() {
    const { target, text, hasArrow = true, onClick, windowTarget = 'self' } = link;

    return (
      <>
        <StyledTypo
          backgroundtype={backgroundType}
          tag="h2"
          size={{
            xsmall: theme.fontTokens.font07,
            medium: theme.fontTokens.font06,
            large: theme.fontTokens.font05,
            xlarge: theme.fontTokens.font04,
          }}
          fontWeight={textFontWeight}
          isUppercase={!isTwyford}
          label={label}
        >
          <InlineEdit previewId={titlePreviewId}>{title}</InlineEdit>
        </StyledTypo>
        <StyledTypo
          backgroundtype={backgroundType}
          tag="span"
          size={{
            xsmall: theme.fontTokens.font07,
            medium: theme.fontTokens.font06,
            large: theme.fontTokens.font05,
            xlarge: theme.fontTokens.font04,
          }}
          fontWeight={spanFontWeight}
          isUppercase={!isTwyford}
          label={label}
        >
          <InlineEdit previewId={subtitlePreviewId}>{subTitle}</InlineEdit>
        </StyledTypo>
        <StyledParagraphContainer>
          <StyledParagraph
            backgroundtype={backgroundType}
            fontSize={isKolo ? 'p2' : fontSize}
            fontWeight={theme.fontWeights.normal}
            label={label}
            className={isKolo ? 'copy-text kolo' : ''}
          >
            <InlineEdit previewId={textPreviewId}>{paragraph}</InlineEdit>
          </StyledParagraph>
        </StyledParagraphContainer>
        {target && text && (
          <StyledLink
            fontSize={isKolo ? 'p2' : fontSize}
            fontWeight={theme.fontWeights.normal}
            hasArrow={hasArrow}
            onClick={(e) => {
              if (typeof onClick === 'function') {
                onClick(e);
              }
            }}
            windowTarget={windowTarget}
            stylingType={linkStylingType}
            text={text}
            target={target}
            alignByContent="left"
            className={classNameBuilder('links-nord', isKolo && 'links-kolo')}
          />
        )}
        {label && <StyledDiv>{label}</StyledDiv>}
      </>
    );
  }

  return (
    <HeadlineWithShortTeaserContainer
      ref={tileRef}
      gap={gap}
      height={height}
      onMouseDown={() => setFocused(false)}
      onKeyUp={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      focused={focused}
      tabIndex={0}
      backgroundType={backgroundType}
      customBackground={customBackground}
      size={size}
      data-testid="headline-with-short-teaser-input"
      {...generatePreviewId(previewId)}
    >
      <TransparentLayer />
      {children ?? renderHeadline()}
      <BorderLayer />
    </HeadlineWithShortTeaserContainer>
  );
}
