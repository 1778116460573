import styled, { css, useTheme } from 'styled-components';

export const LabelWrapper = styled.div(() => {
  const theme = useTheme();

  return css`
    width: 8.25rem;
    height: 3rem;
    bottom: 2.5rem;
    left: 0;

    @media ${theme.device.medium} {
      position: absolute;
    }

    @media ${theme.device.large} {
      bottom: 1.875rem;
    }

    @media ${theme.device.xlarge} {
      bottom: 2.5rem;
    }

    > svg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    > div {
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      height: 100%;
      margin-top: 0.25rem;
    }

    .clean,
    .script {
      display: block;
      color: ${theme.colors.white};
      margin-left: 2.25rem;
      z-index: 1;
      white-space: nowrap;
      text-overflow: clip;
      overflow: hidden;
      width: calc(100% - 2.5rem);
    }

    .clean {
      text-transform: uppercase;
      font-size: 0.625rem;
    }

    .script {
      font-family: 'QuickbrushBaltics', helvetica, arial;
      text-transform: lowercase;
      font-size: 1rem;
    }
  `;
});
