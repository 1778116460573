import type { ThemeType as Theme } from '@geberit/gdds';

import { BackgroundType, States } from '../../tile.types';

type BackgroundStyles = {
  backgroundColor: string | undefined;
  backgroundOpacity: string | undefined;
};

type BorderStyles = {
  borderColor: string | undefined;
  borderOpacity: string | undefined;
};

export function getColorsMap(theme) {
  return {
    black: {
      normal: theme.colors.black,
      transparent: theme.colors.transparentBlack,
    },
    primary: {
      normal: theme.colors.primaryLight,
      transparent: theme.colors.transparentPrimaryLight,
    },
    primaryDark: {
      normal: theme.colors.primaryDark,
    },
    white: {
      normal: theme.colors.white,
      transparent: theme.colors.transparentWhite,
    },
  };
}

export const getDeclarations = (
  theme: Theme,
  backgroundType: BackgroundType,
  customBackground?: string,
) => {
  const colorsMap = getColorsMap(theme);

  const styles: BackgroundStyles = {
    backgroundColor: 'black',
    backgroundOpacity: '0.06',
  };

  if (backgroundType === 'dark') {
    styles.backgroundColor = 'primary';
    styles.backgroundOpacity = undefined;
  }

  if (backgroundType === 'black') {
    styles.backgroundColor = 'black';
    styles.backgroundOpacity = undefined;
  }

  const declarations: Array<string> = [];

  if (styles.backgroundOpacity && styles.backgroundColor && !customBackground) {
    declarations.push(
      `background-color: rgb(${colorsMap[styles.backgroundColor].transparent}, ${
        styles.backgroundOpacity
      });`,
    );
  } else if (customBackground) {
    declarations.push(`background-color: ${customBackground};`);
  } else if (styles.backgroundColor) {
    declarations.push(`background-color: ${colorsMap[styles.backgroundColor].normal};`);
  }

  return declarations;
};

export function checkBorderColorLight(backgroundType, state, styles) {
  if (backgroundType === 'light') {
    if (state === 'focused') {
      styles.borderColor = 'primary';
      styles.borderOpacity = undefined;
    }

    if (state === 'active') {
      styles.borderOpacity = undefined;
    }
  }
}

export function checkBorderColorDark(backgroundType, state, styles, customBackground) {
  if (backgroundType === 'dark') {
    if (state === 'focused') {
      styles.borderColor = customBackground ? 'primary' : 'primaryDark';
      styles.borderOpacity = undefined;
    }

    if (state === 'active') {
      styles.borderOpacity = undefined;
    }
  }
}

export function checkBorderColorBlack(backgroundType, state, styles, setDetaultColor = true) {
  if (backgroundType === 'black') {
    if (setDetaultColor) {
      styles.borderColor = 'black';
      styles.borderOpacity = '0';
    }

    if (state === 'focused') {
      styles.borderColor = 'primary';
      styles.borderOpacity = undefined;
    }

    if (state === 'active') {
      styles.borderColor = 'white';
      styles.borderOpacity = undefined;
    }
  }
}

export const getBorderLayerDeclarations = (
  theme: Theme,
  backgroundType: BackgroundType,
  state?: States,
  customBackground?: boolean,
) => {
  const colorsMap = getColorsMap(theme);

  const styles: BorderStyles = {
    borderColor: 'black',
    borderOpacity: '0',
  };

  checkBorderColorLight(backgroundType, state, styles);
  checkBorderColorDark(backgroundType, state, styles, customBackground);
  checkBorderColorBlack(backgroundType, state, styles);

  const declarations: Array<string> = [];

  if (styles.borderOpacity && styles.borderColor) {
    declarations.push(
      `border: 1px solid rgb(${colorsMap[styles.borderColor].transparent}, ${
        styles.borderOpacity
      });`,
    );
  } else if (styles.borderColor) {
    declarations.push(`border: 1px solid ${colorsMap[styles.borderColor].normal};`);
    if (state === 'focused') {
      declarations.push(`outline: 1px solid ${colorsMap[styles.borderColor].normal};`);
    }
  }

  return declarations;
};
