import React from 'react';

// utils
import { Translation } from '../../Translation/Translation';

// styles
import { LabelWrapper } from './label-wrapper.styles';

export default function Label({ border }) {
  return (
    <LabelWrapper>
      <div>
        <span className="clean">
          <Translation id="web20_tilesmarker_line" />
        </span>
        <span className="script">
          <Translation id="web20_tilesmarker_subline" />
        </span>
      </div>
      <svg
        width="142"
        height="52"
        viewBox="0 0 142 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M141.143 0L120.17 52H0V0H141.143Z" fill="#2D543B" />
        {border && (
          <path opacity="0.2" d="M119.415 51.5H0.5V0.5H140.256L119.415 51.5Z" stroke="white" />
        )}
      </svg>
    </LabelWrapper>
  );
}
