import styled from 'styled-components';
import type { ThemeType as Theme } from '@geberit/gdds';

import { BackgroundType, Size, States } from '../../tile.types';
import {
  checkBorderColorBlack,
  checkBorderColorDark,
  checkBorderColorLight,
  getColorsMap,
} from '../Tlea2/helpers';

interface HeadlineWithShortTeaserProps {
  size: Size;
  backgroundType: BackgroundType;
  focused?: boolean;
  tabIndex?: number;
  imagePosition?: 'left' | 'right';
  customBackground?: string;
}

const sizeMap = {
  small: {
    s: '1 100%',
    m: '1 100%',
    l: '1 100%',
  },
  medium: {
    s: '0 calc(50% - 16px)',
    m: '1 100%',
    l: '1 100%',
  },
  large: {
    s: '0 calc(33.33% - 21.33px)',
    m: '0 calc(66.67% - 1.5rem)',
    l: '0 100%',
  },
};

export const getDeclarations = (
  theme: Theme,
  backgroundType: BackgroundType,
  customBackground?: boolean,
  state?: States,
) => {
  const colorsMap = getColorsMap(theme);

  const styles: { borderColor: string; borderOpacity?: string } = {
    borderColor: 'black',
    borderOpacity: '0',
  };

  checkBorderColorLight(backgroundType, state, styles);
  checkBorderColorDark(backgroundType, state, styles, customBackground);
  checkBorderColorBlack(backgroundType, state, styles, false);

  const declarations: Array<string> = [];

  if (styles.borderOpacity) {
    declarations.push(
      `border: 1px solid rgb(${colorsMap[styles.borderColor].transparent}, ${
        styles.borderOpacity
      });`,
    );
  } else {
    declarations.push(`border: 1px solid ${colorsMap[styles.borderColor].normal};`);
    if (state === 'focused') {
      declarations.push(`outline: 1px solid ${colorsMap[styles.borderColor].normal};`);
    }
  }

  return declarations;
};

export const ImageWithHeadlineAndTeaserContainer = styled.div<HeadlineWithShortTeaserProps>`
  position: relative;
  outline: none;
  ${({ size }) => `flex: ${sizeMap.small[size]};`}
  display: table;
  .border-layer {
    outline: none;
    ${({ theme, backgroundType, customBackground }) =>
      getDeclarations(theme, backgroundType, !!customBackground)}
  }

  display: flex;
  flex-direction: column;

  height: auto;
  box-sizing: border-box;

  :hover {
    .border-layer {
      ${({ theme, backgroundType, customBackground }) =>
        getDeclarations(theme, backgroundType, !!customBackground, 'hovered' as States)}
    }
  }

  :focus {
    .border-layer {
      ${({ theme, backgroundType, focused, customBackground }) =>
        getDeclarations(
          theme,
          backgroundType,
          !!customBackground,
          focused ? ('focused' as States) : undefined,
        )}
    }
  }

  :active:is(:focus) {
    .border-layer {
      ${({ theme, backgroundType, focused, customBackground }) =>
        getDeclarations(
          theme,
          backgroundType,
          !!customBackground,
          !focused ? ('active' as States) : undefined,
        )}
    }
  }

  @media ${({ theme }) => theme.device.medium} {
    ${({ size }) => `flex: ${sizeMap.medium[size]};`}
    ${({ imagePosition }) => `flex-direction: row${imagePosition === 'right' ? '-reverse' : ''};`}
    & > div.image-container {
      flex: 0 0 50%;
    }
    & > div.headline-container {
      flex: 0 0 50%;
    }
  }

  @media ${({ theme }) => theme.device.large} {
    ${({ size }) => `flex: ${sizeMap.large[size]};`}
    & > div.image-container {
      ${({ size }) => `flex: ${size === 'm' ? '1 0 50%' : '1 0 66.66%'};`}
    }
    & > div.headline-container {
      ${({ size }) => `flex: ${size === 'm' ? '1 0 50%' : '1 0 calc(33.33% - 10.67px)'};`}
    }
  }
`;
