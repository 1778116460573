import { useTheme } from 'styled-components';
import { ThemeType as Theme } from '@geberit/gdds';

// types
import type { HeadlineWithShortTeaserProps } from '../../tile.types';

// styles
import { HeadlineWithShortTeaserContainer } from './headline-with-short-teaser.styles';
import {
  StyledDiv,
  StyledLink,
  StyledParagraph,
  StyledParagraphContainer,
  StyledTypo,
  TransparentLayer,
} from '../common/common.styles';

// components
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

// utils
import { useKolo } from 'utils/hooks/use-kolo';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useNord } from 'utils/hooks/use-nord';
import { useTwyford } from 'utils/hooks/use-twyford';

export function HeadlineWithShortTeaser(
  props: Omit<HeadlineWithShortTeaserProps, 'customBackground'> & {
    hovered?: boolean;
    customBackground?: string;
  },
) {
  const {
    title,
    subTitle,
    size,
    paragraph,
    link,
    backgroundType,
    customBackground,
    hovered,
    children,
    fontSize = 'p2',
    tileHeight,
    label,
    titlePreviewId,
    subtitlePreviewId,
    textPreviewId,
  } = props;

  return (
    <HeadlineWithShortTeaserContainer
      className="headline-container"
      backgroundType={backgroundType}
      customBackground={customBackground}
      size={size}
      hovered={hovered}
      tileHeight={tileHeight}
    >
      <TransparentLayer />
      {children || (
        <Content
          title={title}
          subTitle={subTitle}
          paragraph={paragraph}
          link={link}
          backgroundType={backgroundType}
          fontSize={fontSize}
          label={label}
          titlePreviewId={titlePreviewId}
          subtitlePreviewId={subtitlePreviewId}
          textPreviewId={textPreviewId}
        />
      )}
    </HeadlineWithShortTeaserContainer>
  );
}

type ContentProps = Omit<
  HeadlineWithShortTeaserProps,
  'customBackground' | 'size' | 'type' | 'gap' | 'tileHeight'
>;

function Content(props: Readonly<ContentProps>) {
  const {
    title,
    subTitle,
    paragraph,
    link: { target, text, hasArrow = true, onClick, windowTarget },
    backgroundType,
    fontSize = 'p2',
    label,
    titlePreviewId,
    subtitlePreviewId,
    textPreviewId,
  } = props;

  const theme = useTheme() as Theme;
  const isNord = useNord();
  const isKolo = useKolo();
  const isTwyford = useTwyford();

  let stylingType: 'inverted' | 'primary' | 'invertedOnColor' = 'inverted';
  if (backgroundType === 'light') {
    stylingType = 'primary';
  } else if (backgroundType === 'dark') {
    stylingType = 'invertedOnColor';
  }

  let h2FontWeight = isTwyford ? theme.fontWeights.light : theme.fontWeights.normal;
  let spanFontWeight = isTwyford ? theme.fontWeights.medium : theme.fontWeights.normal;
  if (isKolo) {
    h2FontWeight = theme.fontWeights.bold;
    spanFontWeight = theme.fontWeights.bold;
  }

  return (
    <>
      <StyledTypo
        backgroundtype={backgroundType}
        tag="h2"
        size={{
          xsmall: theme.fontTokens.font07,
          medium: theme.fontTokens.font06,
          large: theme.fontTokens.font05,
          xlarge: theme.fontTokens.font04,
        }}
        fontWeight={h2FontWeight}
        isUppercase={!isTwyford}
        label={label}
      >
        <InlineEdit previewId={titlePreviewId}>{title}</InlineEdit>
      </StyledTypo>
      <StyledTypo
        backgroundtype={backgroundType}
        tag="span"
        size={{
          xsmall: theme.fontTokens.font07,
          medium: theme.fontTokens.font06,
          large: theme.fontTokens.font05,
          xlarge: theme.fontTokens.font04,
        }}
        fontWeight={spanFontWeight}
        isUppercase={!isTwyford}
        label={label}
      >
        <InlineEdit previewId={subtitlePreviewId}>{subTitle}</InlineEdit>
      </StyledTypo>
      <StyledParagraphContainer>
        <StyledParagraph
          backgroundtype={backgroundType}
          fontSize={isKolo ? 'p2' : fontSize}
          label={label}
          className={isKolo || isNord ? 'copy-text kolo' : ''}
        >
          <InlineEdit previewId={textPreviewId}>{paragraph}</InlineEdit>
        </StyledParagraph>
      </StyledParagraphContainer>
      {!!target && !!text && (
        <StyledLink
          fontSize={isKolo ? 'p2' : fontSize}
          fontWeight={theme.fontWeights.normal}
          hasArrow={hasArrow}
          onClick={(e) => {
            if (typeof onClick === 'function') {
              onClick(e);
            }
          }}
          windowTarget={windowTarget}
          stylingType={stylingType}
          text={text}
          target={target}
          alignByContent="left"
          className={classNameBuilder('links-nord', isKolo && 'links-kolo')}
        />
      )}
      {label && <StyledDiv>{label}</StyledDiv>}
    </>
  );
}
