// styles
import styles from './video.module.scss';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { useLocatorConfiguration } from 'components/ContentElementsGdds/locator/utils/use-locator-configuration';
import { useUsercentrics } from 'features/cookie-consent';
import { Button } from '@geberit/gdds';
import { decodingContent } from '../../../utils/decodingContent';

export function VideoConsent() {
  const uc = useUsercentrics();
  const locatorConfiguration = useLocatorConfiguration();

  return (
    <div className={classNameBuilder(styles.consentContainer)}>
      <InnerHtml
        as="p"
        content={locatorConfiguration.consent.consentText}
        className={styles.text}
      />
      <Button
        stylingType="secondaryInverted"
        onClick={() => {
          uc?.enableSetting(locatorConfiguration.consent.type);
        }}
      >
        {decodingContent(locatorConfiguration.consent.buttonLabel)}
      </Button>
    </div>
  );
}
