import { Link, LinkProps, Typo, TypographyProps } from '@geberit/gdds';
import { ReactElement } from 'react';
import styled from 'styled-components';

import { BackgroundType } from '../../tile.types';

export const TransparentLayer = styled.div`
  &&& {
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;

export const BorderLayer = styled.div`
  &&& {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    box-sizing: border-box;
    border: 1px solid transparent;
    pointer-events: none;
  }
`;

export const StyledParagraphContainer = styled.div`
  padding: 0.75rem 0 0 0;
`;

export const StyledParagraph = styled.p<{
  backgroundtype: BackgroundType;
  label?: ReactElement;
  fontSize?: 'p1' | 'p2';
  fontWeight?: number;
}>`
  &&& {
    position: relative;
    margin: 0;
    ${({ theme, fontSize }) =>
      `font-size: ${fontSize === 'p1' ? theme.fontTokens.font11[0] : theme.fontTokens.font12[0]};`}
    ${({ theme, fontSize }) =>
      `line-height: ${
        fontSize === 'p1' ? theme.fontTokens.font11[1] : theme.fontTokens.font12[1]
      };`}
  ${({ theme, fontWeight }) => `font-weight: ${fontWeight || theme.fontWeights.regular};`}
  ${({ label }) => `-webkit-line-clamp: ${label ? 2 : 3};`}
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: ${({ theme, backgroundtype }) =>
      backgroundtype === 'light' ? theme.colors.black : theme.colors.white};
  }
`;

export const StyledTypo = styled((props: TypographyProps) => <Typo {...props} />)<
  { backgroundtype: Omit<BackgroundType, 'dark'>; label?: ReactElement } & TypographyProps
>`
  &&& {
    position: relative;
    margin: 0;
    word-break: break-word;
    color: ${({ theme, backgroundtype }) =>
      backgroundtype === 'light' ? theme.colors.black : theme.colors.white};
    span {
      display: block;
    }
    ${({ label }) =>
      `-webkit-line-clamp: ${label ? 1 : 2};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;`}
  }
`;

export const StyledLink = styled((props: LinkProps) => <Link {...props} />)<{
  fontSize: 'p1' | 'p2';
  fontWeight?: number;
}>`
  &&& {
    cursor: pointer;
    padding-top: 0.5rem;

    ${({ fontSize }) => `font-size: ${fontSize === 'p1' ? '16px' : '14px'};`}
    ${({ theme, fontWeight }) => `font-weight: ${fontWeight ?? theme.fontWeights.regular};`}
  }
`;

export const StyledDiv = styled.div`
  margin: 1.5rem 0 1rem -1.5rem;
  display: block;
  width: 100%;
  position: relative;

  svg {
    @media ${({ theme }) => theme.device.medium} {
      position: absolute;
      bottom: 2.5rem;
      left: 0;
    }
    @media ${({ theme }) => theme.device.large} {
      bottom: 1.875rem;
    }

    @media ${({ theme }) => theme.device.xlarge} {
      bottom: 2.5rem;
    }
  }

  @media ${({ theme }) => theme.device.medium} {
    position: unset;
  }
`;
