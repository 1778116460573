import React, { ReactElement, useEffect } from 'react';
import type { ExpressiveTileProps } from '../tile.types';
import { Tlea2 } from './Tlea2/tlea2';
import { Tlea3 } from './Tlea3/tlea3';
import { evaluateProps, clampParagraph } from './utils';

export function ExpressiveTile({
  type,
  backgroundType = 'dark',
  customBackground,
  subTitle,
  imageSize = 's',
  emphasize = 'title',
  title,
  paragraph,
  image,
  imageTitle,
  link,
  imagePosition = 'left',
  children,
  altText,
  gap,
  fontSize = 'p2',
  breakPoint,
  imageStyles,
  label,
  previewId,
  titlePreviewId,
  subtitlePreviewId,
  textPreviewId,
}: ExpressiveTileProps): ReactElement | null {
  useEffect(() => {
    evaluateProps(backgroundType, customBackground, type);
  }, [backgroundType, customBackground]);

  if (type === 'tlea2') {
    return (
      <Tlea2
        gap={gap}
        backgroundType={backgroundType}
        customBackground={customBackground}
        type="tlea2"
        title={title}
        subTitle={subTitle}
        size="s"
        fontSize={fontSize}
        link={link}
        paragraph={clampParagraph(paragraph)}
        emphasize={emphasize}
        label={label}
        previewId={previewId}
        titlePreviewId={titlePreviewId}
        subtitlePreviewId={subtitlePreviewId}
        textPreviewId={textPreviewId}
      >
        {children}
      </Tlea2>
    );
  }

  if (type === 'tlea3') {
    return (
      <Tlea3
        breakPoint={breakPoint}
        gap={gap}
        imagePosition={imagePosition}
        type="tlea3"
        imageSize={imageSize}
        fontSize={fontSize}
        backgroundType={backgroundType}
        customBackground={customBackground}
        title={title}
        subTitle={subTitle}
        link={link}
        paragraph={clampParagraph(paragraph)}
        image={image}
        altText={altText}
        imageTitle={imageTitle}
        emphasize={emphasize}
        imageStyles={imageStyles}
        label={label}
        previewId={previewId}
        titlePreviewId={titlePreviewId}
        subtitlePreviewId={subtitlePreviewId}
        textPreviewId={textPreviewId}
      >
        {children}
      </Tlea3>
    );
  }

  return null;
}
