import { ReactElement } from 'react';
import warning from 'warning';

export function evaluateProps(backgroundType: string, customBackground: string | undefined, type) {
  warning(
    ['light', 'black', 'dark'].includes(backgroundType),
    'backgroudType should be from type BackgroundType.',
  );
  if (customBackground) {
    warning(
      !['light', 'black'].includes(backgroundType),
      'customBackground should be passed only if backgroundType is dark.',
    );
  }
  warning(
    ['imageWithHeadline', 'headlineWithShortTeaser', 'imageWithHeadlineAndTeaser'].includes(type),
    "use the new type prop 'tlea2' & 'tlea3'",
  );
}
export const clampParagraph = (
  paragraph: string | ReactElement | Array<ReactElement> | undefined,
) => {
  if (typeof paragraph === 'string') {
    return paragraph.length >= 140 ? `${paragraph.slice(0, 140)}…` : paragraph;
  }
  return paragraph;
};
