import styled from 'styled-components';

import { Size, ImageStyles } from '../../tile.types';

interface ImageWithHeadlineContainerProps {
  size: Size;
  hovered?: boolean;
  tileHeight?: number;
}

const sizeMap = {
  small: {
    s: '1',
    m: '1',
    l: '1',
  },
  medium: {
    s: '0 calc(50% - 16px)',
    m: '1 100%',
    l: '1 100%',
  },
  large: {
    s: '0 calc(33.33% - 21.33px)',
    m: '0 calc(66.66% - 24px)',
    l: '0 100%',
  },
};

export const ImageWithHeadlineContainer = styled.div<ImageWithHeadlineContainerProps>`
  position: relative;
  display: block;

  & .image-container {
    transition: transform 0.25s ease-in-out;
    transform: ${({ hovered }) => (hovered ? 'scale(1.03)' : '')};
  }

  @media ${({ theme }) => theme.device.small} {
    display: block;
    overflow: inherit;
    ${({ size }) => `flex: ${sizeMap.small[size]}`};
    > div {
      overflow: hidden;
    }
  }

  @media ${({ theme }) => theme.device.medium} {
    display: block;
    overflow: hidden;
    ${({ size }) => `flex: ${sizeMap.medium[size]}`};
    ${({ tileHeight }) => `height: ${tileHeight}px`};
  }

  @media ${({ theme }) => theme.device.large} {
    ${({ size }) => `flex: ${sizeMap.large[size]};`}
  }

  @media ${({ theme }) => theme.device.xlarge} {
    max-height: 21.75rem;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const ImageWrapper = styled.div<{
  imageSource: string;
  imageStyles: Partial<ImageStyles>;
}>`
  width: 100%;
  height: 100%;
  background-color: black;
  ${({ imageSource: image }) => `background: url(${image});`}
  background-size: ${({ imageStyles }) => imageStyles.size};
  background-repeat: ${({ imageStyles }) => (imageStyles.repeat ? 'repeat' : 'no-repeat')};
  background-position: center center;
`;
