import styled from 'styled-components';

export const TilesContainer = styled.div<{
  type: 'productive' | 'expressive';
  gap?: string;
}>`
  display: flex;
  box-sizing: border-box;
  flex-flow: ${({ type }) => (type === 'expressive' ? 'row wrap' : 'column')};

  ${({ gap }) =>
    `margin: calc(-1 * ${gap}) 0 0 calc(-1 * ${gap});
    width: calc(100% + ${gap});
    > * {
      margin: ${gap} 0 0 ${gap};
    }`}

  @media ${({ theme }) => theme.device.medium} {
    flex-flow: ${({ type }) => (type === 'expressive' ? 'row wrap' : 'row')};
  }
`;
