import { gddsTileColor } from '../../components/ContentElementsGdds/TeaserTilesNordics/types';
import type {
  TileColorProps,
  TileBackgroundColorProps,
} from '../../components/ContentElementsGdds/TeaserTilesNordics/types';
import type { PossibleThemeName } from './use-theme';

export const getTileColor = (
  color: TileColorProps,
  themeName: PossibleThemeName,
): TileBackgroundColorProps => {
  const isNordicsTheme = themeName === 'NORD';
  const isKoloTheme = themeName === 'KOLO';
  const isTwyfordTheme = themeName === 'TWYFORD';

  const isGrey =
    color === 'grey' || (isNordicsTheme && color === 'teal') || (isTwyfordTheme && color === 'teal') || (isKoloTheme && color === 'green');

  return isGrey ? gddsTileColor['grey'] : gddsTileColor[color];
};
