import type { ThemeType as Theme } from '@geberit/gdds';

import { BackgroundType, States } from '../../tile.types';

export const getTransparentLayerDeclarations = (
  theme: Theme,
  backgroundType: BackgroundType,
  state?: States,
) => {
  const colorMap = {
    black: theme.colors.transparentBlack,
    primary: theme.colors.transparentPrimaryLight,
    white: theme.colors.transparentWhite,
  };

  const styles = {
    transparentLayer: 'black',
    transparentLayerOpacity: '0',
  };

  if (backgroundType === 'light') {
    if (state === 'hovered') {
      styles.transparentLayerOpacity = '0.04';
    }
  }

  if (backgroundType === 'dark') {
    if (state === 'hovered') {
      styles.transparentLayerOpacity = '0.08';
    }
  }

  if (backgroundType === 'black') {
    styles.transparentLayer = 'white';
    styles.transparentLayerOpacity = '0';

    if (state === 'hovered') {
      styles.transparentLayerOpacity = '0.12';
    }
  }

  const declarations: Array<string> = [];

  declarations.push(
    `background-color: rgb(${colorMap[styles.transparentLayer]}, ${
      styles.transparentLayerOpacity
    });`,
  );

  return declarations;
};
