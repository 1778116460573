import { useState } from 'react';
import { useRouter } from 'next/router';
import { Form, FormField, Button } from '@geberit/gdds';

// components
import { Headline, FontWeights, Formats } from 'components/ContentElements/Headline';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { locatorTileSubmit } from 'components/ContentElements/Teaser/trackingActions';

// styles
import { LocatorTileWrapper } from './locator-tile-wrapper.styles';

// types
import { TileBackgroundColorProps } from './types';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { decodingContent } from 'utils/decodingContent';
import { classNameBuilder } from 'utils/classNameBuilder';
import { previewId as generatePreviewId } from 'utils/preview-id';
import { useKolo } from 'utils/hooks/use-kolo';
import { useTwyford } from 'utils/hooks/use-twyford';
import { isEmpty } from 'utils/is-empty';

interface LocatorTileProps {
  backgroundType: TileBackgroundColorProps;
  searchPlaceholder?: string;
  title?: string;
  color?: string;
  subtitle?: string;
  link?: Link;
  text?: string;
  previewId?: string;
  titlePreviewId?: string;
  subtitlePreviewId?: string;
  textPreviewId?: string;
  reset?: (...args: unknown[]) => void;
}

function LocatorTile({ backgroundType, ...props }: Readonly<LocatorTileProps>) {
  const {
    title,
    subtitle,
    text,
    link,
    searchPlaceholder,
    previewId,
    titlePreviewId,
    subtitlePreviewId,
    textPreviewId,
    reset,
  } = props;
  const track = useTracking();
  const router = useRouter();
  const [locationValue, setLocationValue] = useState();
  const [error, setError] = useState('');
  const cutText = (paragraph) =>
    paragraph.length >= 140 ? `${paragraph.slice(0, 140)}…` : paragraph;
  const isKolo = useKolo();
  const isTwyford = useTwyford();

  const onSubmit = (e) => {
    e.preventDefault();

    if (isEmpty(locationValue)) {
      setError('postcode empty!');
    } else {
      track.trackEvent(locatorTileSubmit(locationValue));
      router.push(`${link.target}?location=${locationValue}`);
    }
  };

  const onChange = (e) => {
    if (error) {
      setError('');
    }
    const target = e.currentTarget.value;
    setLocationValue(target);
  };

  let titleFontWeight = isTwyford ? FontWeights.light : FontWeights.regular;
  let subtitleFontWeight = isTwyford ? FontWeights.medium : FontWeights.regular;
  if (isKolo) {
    titleFontWeight = FontWeights.bold;
    subtitleFontWeight = FontWeights.bold;
  }

  return (
    <LocatorTileWrapper
      backgroundType={backgroundType}
      {...generatePreviewId(previewId)}
      tabIndex={0}
    >
      <div className="background-hover"></div>
      <Headline
        format={Formats.h2}
        titleFontWeight={titleFontWeight}
        subtitleFontWeight={subtitleFontWeight}
        isUppercase
        title={decodingContent(title)}
        subtitle={decodingContent(subtitle)}
        titlePreviewId={titlePreviewId}
        subtitlePreviewId={subtitlePreviewId}
      />
      <InnerHtml
        previewId={textPreviewId}
        content={cutText(text)}
        as="div"
        className="copy-text"
        isGdds
      />
      <div className={classNameBuilder('search-input', error && 'error')}>
        <Form onSubmit={onSubmit}>
          <FormField
            name="postcode"
            placeholder={decodingContent(searchPlaceholder)}
            values={locationValue}
            reset={() => {
              if (typeof reset === 'function') {
                reset('postcode');
              }
            }}
            onChange={onChange}
          />
          <Button buttonType="submit" stylingType="flatInverted" symbol="Search" isIcon />
        </Form>
        {error && <span className={error ? 'error' : 'valid'}>Please enter postcode</span>}
      </div>
    </LocatorTileWrapper>
  );
}

export default LocatorTile;
