import styled, { css, useTheme } from 'styled-components';
import { styleConfig } from '../../../config';
import { getDeclarations } from '../CustomGdds/Tiles/src/tile/expressive/Tlea3/image-with-headline-and-teaser.styles';

// types
import type { TileBackgroundColorProps } from './types';

// utils
import { useNord } from 'utils/hooks/use-nord';
import { pxToRem } from 'utils/pxToRem';
import { useKolo } from 'utils/hooks/use-kolo';

type LocatorTileWrapperProps = {
  backgroundType: TileBackgroundColorProps;
};

type ColorsMapType = {
  [backgroundType: string]: {
    background: string;
    color: string;
    hover: string;
  };
};

export const LocatorTileWrapper = styled.div((props: LocatorTileWrapperProps) => {
  const { backgroundType } = props;
  const theme = useTheme();
  const isLight = backgroundType === 'light';
  const focusStyles = getDeclarations(theme, backgroundType, false, 'focused');
  const isKolo = useKolo();
  const isNord = useNord();

  const colorsMap: ColorsMapType = {
    dark: {
      background: theme.colors.primaryLight,
      color: theme.colors.white,
      hover: `rgb(${theme.colors.transparentBlack}, 0.08)`,
    },
    light: {
      background: `rgb(${theme.colors.transparentBlack}, 0.06)`,
      color: theme.colors.black,
      hover: `rgb(${theme.colors.transparentBlack}, 0.04)`,
    },
    black: {
      background: `rgb(${theme.colors.transparentBlack})`,
      color: theme.colors.white,
      hover: `rgb(${theme.colors.transparentWhite}, 0.12)`,
    },
  };

  return css`
    &&& {
      position: relative;
      color: ${colorsMap[backgroundType]?.color};

      &,
      &[data-preview-id] {
        min-height: 13.5rem;
      }

      box-sizing: border-box;
      padding: 2rem;
      outline: none;
      flex: 1 100%;
      box-sizing: border-box;
      background-color: ${colorsMap[backgroundType]?.background};
      border: 1px solid transparent;

      :focus {
        ${focusStyles.map((style) => style)}
      }

      .background-hover {
        pointer-events: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
      }

      &:hover > .background-hover {
        background-color: ${colorsMap[backgroundType]?.hover};
      }

      &:active {
        border: 1px solid ${theme.colors.black};
      }

      ${isKolo &&
      css`
        h2 {
          font-size: 2rem;
          line-height: 2.5rem;
        }
      `}

      @media (min-width: ${pxToRem(styleConfig.responsive.mobileGdds)}) {
        &,
        &[data-preview-id] {
          min-height: 21rem;
        }
        flex: 0 calc(50% - 1.5rem);
      }

      @media ${theme.device.large} {
        &,
        &[data-preview-id] {
          min-height: 18.5rem;
        }
        flex: 0 calc(33.33% - 1.5rem);
      }

      @media ${theme.device.xlarge} {
        &,
        &[data-preview-id] {
          min-height: 21rem;
        }
      }

      h2 {
        margin-bottom: 0.75rem;
      }

      form {
        border-bottom: 1px solid
          ${(() => {
            if (isLight) {
              return theme.colors.cl14;
            }

            return theme.colors.white;
          })()};
        width: 100%;
        display: flex;
        flex-direction: row;

        svg {
          fill: ${isLight ? theme.colors.grey12 : theme.colors.white};
        }
      }

      .copy-text {
        font-size: ${isKolo ? '0.875rem' : '1rem'};
        font-weight: ${isKolo || isNord ? theme.fontWeights.normal : theme.fontWeights.regular};
        line-height: ${isKolo && '1.25rem'};

        @media ${theme.device.large} {
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      .search-input {
        width: 100%;
        margin-top: 2.2rem;
        margin-bottom: 1rem;

        .error {
          color: ${theme.colors.error};
          position: absolute;
        }

        .valid {
          display: none;
        }

        @media (min-width: ${pxToRem(styleConfig.responsive.mobileGdds)}) {
          position: absolute;
          margin: 0 0 0 -2rem;
          bottom: 5rem;
          padding: 0 2rem;
        }

        @media ${theme.device.large} {
          bottom: 3.5rem;
        }

        @media ${theme.device.xlarge} {
          bottom: 5rem;
          min-height: unset;
          flex: 0 calc(33.33% - 1.5rem);
        }

        input[type='text'] {
          font-size: 1rem;
          background-color: transparent;
          border: 0;
          color: ${isLight ? theme.colors.grey12 : theme.colors.white};
          padding-left: 0.5rem;

          &::placeholder {
            opacity: 1;
            color: ${isLight ? theme.colors.grey12 : theme.colors.grey09};
          }

          &:hover,
          &:focus {
            box-shadow: none;
          }
        }

        > div {
          flex: 1;
        }

        button {
          position: absolute;
          right: 2rem;

          span {
            right: 0;
          }
        }
      }
    }
  `;
});
