import key from 'weak-key';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from '@geberit/gdds';

// styles
import { TeaserTilesWrapper } from './teaser-tiles-wrapper.styles';

// types
import type { Content } from './teaser-tiles.types';

// components
import TileLoader from './tile-loader';
import { Tiles } from '../CustomGdds/Tiles/src/tiles/tiles';
import { Headline, FontWeights, Formats } from 'components/ContentElements/Headline';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { buildSize, gridSizes } from 'utils/gridSize';
import { currentPageSelector } from 'utils/selectors/pageSelectors';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { useKolo } from 'utils/hooks/use-kolo';
import { useSectionId } from 'utils/hooks/use-section-id';
import { useTwyford } from 'utils/hooks/use-twyford';
import { useKoloOrTwyford } from 'utils/hooks/use-kolo-twyford';
import { isEmpty } from 'utils/is-empty';

export function TeaserTiles({
  title,
  subtitle,
  tiles,
  text,
  anchor,
  titleInAnchor,
  contentIndex,
}: Readonly<Content>) {
  const page = useSelector(currentPageSelector);
  const hasHeroTile = !isEmpty(page.page?.heroTile);
  const hasTextbox = title || subtitle || text;
  const isKolo = useKolo();
  const isTwyford = useTwyford();
  const isKoloOrTwyford = useKoloOrTwyford();
  const sectionId = useSectionId(title, anchor, titleInAnchor, contentIndex);
  const isPreview = useIsPreview();

  let subtitleFontWeight = isTwyford ? FontWeights.light : undefined;
  if (isKolo) subtitleFontWeight = FontWeights.bold;

  return (
    <TeaserTilesWrapper hasHeroTile={hasHeroTile} id={sectionId}>
      {hasTextbox && (
        <div className="text-wrapper">
          <Container maxContentWidth={buildSize(gridSizes['gddsFullGrid'])}>
            <Row>
              <Col size={[4, 8, 8]} className="centered-col">
                {(title || subtitle) && (
                  <Headline
                    format={Formats.h2}
                    title={title}
                    subtitle={subtitle}
                    titleFontWeight={isKoloOrTwyford ? FontWeights.bold : FontWeights.regular}
                    subtitleFontWeight={subtitleFontWeight}
                    titlePreviewId="#st_title"
                    subtitlePreviewId="#st_subtitle"
                  />
                )}
                {text && (
                  <InnerHtml
                    content={text}
                    as="div"
                    className="copy-text"
                    isGdds
                    previewId="#st_text"
                  />
                )}
              </Col>
            </Row>
          </Container>
        </div>
      )}
      <div>
        <Container maxContentWidth={buildSize(gridSizes['gddsFullGrid'])}>
          <Row>
            <Col size={[4, 8, 12]}>
              <Tiles previewId={isPreview ? '#st_teaserTiles' : undefined} type="expressive">
                {tiles.map((item, index) => (
                  <TileLoader tileIndex={index} key={key(item)} {...item} />
                ))}
              </Tiles>
            </Col>
          </Row>
        </Container>
      </div>
    </TeaserTilesWrapper>
  );
}
