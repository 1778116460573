import styled from 'styled-components';

import { BackgroundType, Size, States } from '../../tile.types';
import { BorderLayer, TransparentLayer } from '../common/common.styles';
import { getDeclarations, getBorderLayerDeclarations } from './helpers';
import { getTransparentLayerDeclarations } from '../common/helpers';

interface HeadlineWithShortTeaserProps {
  size: Size;
  backgroundType: BackgroundType;
  customBackground?: string;
  focused: boolean;
  gap: string;
  height: number;
}

const sizeMap = (gap: string) => ({
  small: {
    s: '1 100%',
  },
  medium: {
    s: `0 calc(50% - ${gap})`,
  },
  large: {
    s: `0 calc(33.33% - ${gap})`,
  },
});

export const HeadlineWithShortTeaserContainer = styled.div<HeadlineWithShortTeaserProps>`
  &&& {
    ${({ size, gap }) => `flex: ${sizeMap(gap).small[size]};`}
    ${({ theme, backgroundType, customBackground }) =>
      getDeclarations(theme, backgroundType, customBackground)}
  & ${BorderLayer} {
      ${({ theme, backgroundType, customBackground }) =>
        getBorderLayerDeclarations(theme, backgroundType, undefined, !!customBackground)}
    }

    position: relative;
    min-height: 13.5rem;
    box-sizing: border-box;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    outline: none;

    & .image-container {
      transition: all 0.25s ease-in-out;
    }

    :hover {
      ${({ theme, backgroundType, customBackground }) =>
        getDeclarations(theme, backgroundType, customBackground)}
      & ${TransparentLayer} {
        ${({ theme, backgroundType }) =>
          getTransparentLayerDeclarations(theme, backgroundType, 'hovered' as States)}
      }
      & ${BorderLayer} {
        ${({ theme, backgroundType, customBackground }) =>
          getBorderLayerDeclarations(
            theme,
            backgroundType,
            'hovered' as States,
            !!customBackground,
          )}
      }
      & .image-container {
        transform: scale(1);
      }
    }

    :focus {
      ${({ theme, backgroundType, customBackground }) =>
        getDeclarations(theme, backgroundType, customBackground)}

      & ${BorderLayer} {
        ${({ theme, backgroundType, focused, customBackground }) =>
          getBorderLayerDeclarations(
            theme,
            backgroundType,
            focused ? ('focused' as States) : undefined,
            !!customBackground,
          )}
      }
    }

    :active:is(:focus) {
      ${({ theme, backgroundType, customBackground }) =>
        getDeclarations(theme, backgroundType, customBackground)}

      & ${BorderLayer} {
        ${({ theme, backgroundType, focused, customBackground }) =>
          getBorderLayerDeclarations(
            theme,
            backgroundType,
            !focused ? ('active' as States) : undefined,
            !!customBackground,
          )}
      }
    }

    .copy-text {
      &.kolo {
        ${({ theme }) => `font-weight: ${theme.fontWeights.normal};`}
      }
    }

    .links-nord {
      padding-top: 0 !important;

      &.links-kolo {
        svg {
          width: 1.25rem;
        }
      }
    }

    @media ${({ theme }) => theme.device.medium} {
      ${({ size, gap }) => `flex: ${sizeMap(gap).medium[size]};`}
      padding: 2rem;
      ${({ height }) => `height: ${height}px;`}
    }

    @media ${({ theme }) => theme.device.large} {
      ${({ size, gap }) => `flex: ${sizeMap(gap).large[size]};`}
    }

    @media ${({ theme }) => theme.device.xlarge} {
      max-height: 21.75rem;
    }
  }
`;
