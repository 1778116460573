import styled, { css, useTheme } from 'styled-components';
import { useKolo } from 'utils/hooks/use-kolo';
import { useTwyford } from 'utils/hooks/use-twyford';

type TeaserTilesWrapperProps = {
  hasHeroTile: boolean;
};

export const TeaserTilesWrapper = styled.div(({ hasHeroTile }: TeaserTilesWrapperProps) => {
  const isKolo = useKolo();
  const isTwyford = useTwyford();
  const theme = useTheme();

  return css`
    margin: 1.25rem 0;

    ${hasHeroTile &&
    css`
      &.hero-tile-margin {
        margin-top: 1.5rem;
      }
    `}

    @media ${theme.device.large} {
      margin: 5rem 0;
    }

    .centered-col {
      margin: 0 auto;

      h2 {
        margin-bottom: 1rem;
      }

      .copy-text {
        font-size: 1rem;

        ${isKolo &&
        css`
          line-height: 1.5rem;
        `}
      }
    }

    .text-wrapper {
      margin-bottom: 1.25rem;

      @media ${theme.device.medium} {
        margin-bottom: 2.5rem;
      }

      ${isTwyford &&
      css`
        h2 {
          font-weight: 700;

          span {
            font-weight: 700;
          }

          span[class*='headline_subtitle'] {
            font-weight: 300;

            span {
              font-weight: 300;
            }
          }
        }
      `}
    }
  `;
});
