import { useContext } from 'react';
import { Notification } from '@geberit/gdds';

// components
import { Headline, FontWeights, Formats } from 'components/ContentElements/Headline';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { GoogleMapProvider } from '../locator/overview-page/google-map-provider';
import { SearchContainer, SearchContext } from '../locator/search';

// utils
import { decodingContent } from 'utils/decodingContent';
import { useKolo } from 'utils/hooks/use-kolo';
import { useTwyford } from 'utils/hooks/use-twyford';
import { useUCConsent } from 'features/cookie-consent';
import { useLocatorConfiguration } from '../locator/utils/use-locator-configuration';

const cutText = (paragraph: string) =>
  paragraph.length >= 140 ? `${paragraph.slice(0, 140)}…` : paragraph;

export interface ShowroomLocatorTileProps {
  searchPlaceholder?: string;
  title?: string;
  color?: string;
  subtitle?: string;
  link?: Link;
  text?: string;
  previewId?: string;
  titlePreviewId?: string;
  subtitlePreviewId?: string;
  textPreviewId?: string;
  reset?: (...args: unknown[]) => void;
}

export function ShowroomLocatorTileContent(
  props: Readonly<Omit<ShowroomLocatorTileProps, 'backgroundType'>>,
) {
  const { title, subtitle, text, titlePreviewId, subtitlePreviewId, textPreviewId } = props;
  const consent = useUCConsent();
  const { error, resetError } = useContext(SearchContext);
  const showInputField = consent.Comfort === true;
  const locatorConfiguration = useLocatorConfiguration();
  const isKolo = useKolo();
  const isTwyford = useTwyford();

  let titleFontWeight = isTwyford ? FontWeights.light : FontWeights.regular;
  let subtitleFontWeight = isTwyford ? FontWeights.medium : FontWeights.regular;
  if (isKolo) {
    titleFontWeight = FontWeights.bold;
    subtitleFontWeight = FontWeights.bold;
  }

  return (
    <div>
      <Headline
        format={Formats.h2}
        titleFontWeight={titleFontWeight}
        subtitleFontWeight={subtitleFontWeight}
        isUppercase
        title={decodingContent(title)}
        subtitle={decodingContent(subtitle)}
        titlePreviewId={titlePreviewId}
        subtitlePreviewId={subtitlePreviewId}
      />
      <InnerHtml
        previewId={textPreviewId}
        content={cutText(text ?? '')}
        as="div"
        className="copy-text"
        isGdds
      />
      <GoogleMapProvider>{showInputField && <SearchContainer />}</GoogleMapProvider>
      {error && (
        <Notification
          type="broadcast"
          text={locatorConfiguration?.consent?.geoLocationFailureNote ?? ''}
          buttonStyleType="flatInverted"
          showCloseButton
          positionTop={0}
          onClick={resetError}
          className="notification"
        />
      )}
    </div>
  );
}
